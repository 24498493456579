import { GetterTree } from 'vuex'
import { State } from './index'
import type { State as RootState } from '@/store'

export type Getters<S = State> = {
  filteredVoices(state: S): Array<string>;
}

export const getters: GetterTree<State, RootState> & Getters = {
  filteredVoices: (state) => {
    const selectedLanguage = state.selectedLanguage
    let selectedVoice = 0
    if (Object.keys(state.selectedVoice).length) {
      selectedVoice = state.selectedVoice[0].value
    }
    let voices = []
    if (state.selectedLanguage.length > 0) {
      voices = state.voicesAndLanguages.filter(
        voice => voice.languageCodes[0] === selectedLanguage && voice.name.includes(state.voiceType)
      ).map((voice, i) => {
        return { value: i, label: voice.ssmlGender, voiceCode: voice.name, selected: selectedVoice };
      })
    }
    voices = voices.filter(
      (v, i, a) => a.findIndex((v2) => v2.voiceCode === v.voiceCode) === i
    );
    return voices
  },
}
