import { audioUrlBody, vogTempUserPayload, saveUserDatapayload } from './../../types/index';
import { ActionContext, ActionTree } from 'vuex'
import { Mutations, VoicesMutationTypes } from './mutations'
import { State } from './index'
import { State as RootState } from '@/store'
import axios from 'axios'
import noAuthApi from '@/api/noAuth'
import router from "@/router";

export enum VoicesActionTypes {
  GET_VOICES_AND_LANGUAGES = 'GET_VOICES_AND_LANGUAGES',
  GET_USER_DATA = "GET_USER_DATA",
  GET_AUDIO_URL = 'SET_AUDIO_URL',
  GET_TEMP_USER_DATA = 'GET_TEMP_USER_DATA',
  SAVE_USER_DATA = 'SAVE_USER_DATA',
  UPDATE_DOWNLOAD_COUNT = 'UPDATE_DOWNLOAD_COUNT',
  CREATE_PROJECT = 'CREATE_PROJECT',
  UPDATE_PROJECT = 'UPDATE_PROJECT'
}


type AugmentedActionContext = {
  commit<K extends keyof Mutations>(key: K, payload: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
  [VoicesActionTypes.GET_VOICES_AND_LANGUAGES]({ commit }: AugmentedActionContext, payload: void): void;
  [VoicesActionTypes.GET_USER_DATA]({ commit }: AugmentedActionContext, payload: void): void;
  [VoicesActionTypes.GET_AUDIO_URL]({ commit }: AugmentedActionContext, payload: audioUrlBody): void;
  [VoicesActionTypes.GET_TEMP_USER_DATA]({ commit }: AugmentedActionContext, payload: vogTempUserPayload): void;
  [VoicesActionTypes.SAVE_USER_DATA]({ commit }: AugmentedActionContext, payload: saveUserDatapayload): void;
  [VoicesActionTypes.UPDATE_DOWNLOAD_COUNT]({ commit }: AugmentedActionContext, payload: void): void;
  [VoicesActionTypes.CREATE_PROJECT]({ commit }: AugmentedActionContext, payload: void): void;
  [VoicesActionTypes.UPDATE_PROJECT]({ commit }: AugmentedActionContext, payload: void): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [VoicesActionTypes.GET_VOICES_AND_LANGUAGES]({ commit }) {
    commit(VoicesMutationTypes.SET_LOADER, true)
    try {
      const res = await
        axios.get('https://texttospeech.googleapis.com/v1beta1/voices?key=AIzaSyCpE0t4v_h4NTJbTSEIaAuLuV0FmzahJD0')

      commit(VoicesMutationTypes.SET_VOICES_AND_LANGUAGES, res.data.voices)
    } catch (error) {
      commit(VoicesMutationTypes.SET_API_ERROR_LOADER, true)
    }
    commit(VoicesMutationTypes.SET_LOADER, false)
  },
  async [VoicesActionTypes.GET_USER_DATA]({ commit }) {
    const routeQuery = router.currentRoute.value.query;
    if (routeQuery.projectId) {
      commit(VoicesMutationTypes.SET_LOADER, true)
      // const res = await 
      // axios.get('https://ivcqahposk.execute-api.eu-central-1.amazonaws.com/prod/item/'+routeQuery.projectId)
      // axios.get('https://un2sssjr9g.execute-api.eu-central-1.amazonaws.com/dev/item/'+routeQuery.projectId)
      // commit(VoicesMutationTypes.SET_USER_DATA, res.data)
      try {
        const headers = { "Authorization": `Bearer ${routeQuery.token}`, "Access-Control-Allow-Methods": "*" }
        const projectRes = (await axios.get(`${routeQuery.apiUrl}/api/v2/generators/${routeQuery.projectId}`, { headers })).data
        const res = await axios.get('https://ivcqahposk.execute-api.eu-central-1.amazonaws.com/prod/item/' + projectRes.data.download_id)
        commit(VoicesMutationTypes.SET_USER_DATA, res.data)
        commit(VoicesMutationTypes.SET_PROJECT_DATA, projectRes.data)
        "dataLayer" in window
          ? window.dataLayer.push({
            event: "vog_open_item",
            eventCategory: "voice_over_generator",
            eventLabel: `vog_project_slug_${projectRes.data.slug}`,
          })
          : null;
      } catch (error) {
        commit(VoicesMutationTypes.SET_API_ERROR_LOADER, true)
      }
      commit(VoicesMutationTypes.SET_LOADER, false)
    } else if (routeQuery.token) {
      commit(VoicesMutationTypes.SET_LOADER, true)
      try {
        const headers = { "Authorization": `Bearer ${routeQuery.token}`, "Access-Control-Allow-Methods": "*" }
        const projectPagesUser = (await axios.get(`${routeQuery.apiUrl}/api/v1/user`, { headers })).data
        commit(VoicesMutationTypes.SET_PROJECT_PAGES_USER_DATA, projectPagesUser.data)
      } catch (error) {
        commit(VoicesMutationTypes.SET_API_ERROR_LOADER, true)
      }
      commit(VoicesMutationTypes.SET_LOADER, false)
    }
  },
  async [VoicesActionTypes.GET_AUDIO_URL]({ commit }, payload) {
    commit(VoicesMutationTypes.SET_LOADER, true)
    // const res = await axios.post('https://un2sssjr9g.execute-api.eu-central-1.amazonaws.com/dev/audio', payload)
    try {
      const res = await noAuthApi.getAudioUrl(payload)
      commit(VoicesMutationTypes.SET_AUDIO_URL, res.audioURL)
    } catch (error) {
      let err = "";
      error === 'PunctuationError' ?
        err = "punctuationError" : err = 'functionError'
      commit(VoicesMutationTypes.SET_FUNC_ERROR, err)
      commit(VoicesMutationTypes.SET_AUDIO_URL, '')
    }
    commit(VoicesMutationTypes.SET_LOADER, false)
  },
  async [VoicesActionTypes.GET_TEMP_USER_DATA]({ commit }, payload) {
    const res = await noAuthApi.getTempUserData(payload)
    // const res = await axios.post('https://ivcqahposk.execute-api.eu-central-1.amazonaws.com/prod/item', payload)  
    commit(VoicesMutationTypes.SET_TEMP_USER_DATA, res)
  },
  async [VoicesActionTypes.SAVE_USER_DATA]({ commit }, payload) {
    // const res = await axios.post('https://ivcqahposk.execute-api.eu-central-1.amazonaws.com/prod/item', payload)
    const res = await noAuthApi.saveUserData(payload)
    await commit(VoicesMutationTypes.SET_USER_DATA, res)
  },
  async [VoicesActionTypes.UPDATE_DOWNLOAD_COUNT]({ commit }, payload) {
    const routeQuery = router.currentRoute.value.query;
    commit(VoicesMutationTypes.SET_LOADER, true)
    const headers = { "Authorization": `Bearer ${routeQuery.token}`, "Content-Type": "application/json" }
    const data = JSON.stringify({
      "download": payload
    })
    const projectRes = await axios.put(`${routeQuery.apiUrl}/api/v2/generators/${routeQuery.projectId}/download`, data, { headers: headers })
    if (projectRes.status == 200) {
      commit(VoicesMutationTypes.SET_PROJECT_DATA, projectRes.data.data)
    }
    commit(VoicesMutationTypes.SET_LOADER, false)
  },
  async [VoicesActionTypes.CREATE_PROJECT]({ commit }, payload) {
    const routeQuery = router.currentRoute.value.query;
    commit(VoicesMutationTypes.SET_LOADER, true)
    const headers = { "Authorization": `Bearer ${routeQuery.token}`, "Content-Type": "application/json" }
    try {
      const projectRes = await axios.post(`${routeQuery.apiUrl}/api/v2/generators`, payload, { headers: headers })
      if (projectRes.status == 201) {
        commit(VoicesMutationTypes.SET_PROJECT_DATA, projectRes.data.data)
      }
    } catch (error) {
      commit(VoicesMutationTypes.SET_API_ERROR_LOADER, true)
    }
    commit(VoicesMutationTypes.SET_LOADER, false)
  },
  async [VoicesActionTypes.UPDATE_PROJECT]({ commit }, payload) {
    const routeQuery = router.currentRoute.value.query;
    commit(VoicesMutationTypes.SET_LOADER, true)
    const headers = { "Authorization": `Bearer ${routeQuery.token}`, "Content-Type": "application/json" }
    // const data = JSON.stringify({
    //   "download_id": payload
    // })
    try {
      const projectRes = await axios.put(`${routeQuery.apiUrl}/api/v2/generators/${routeQuery.projectId}`, payload, { headers: headers })
      if (projectRes.status == 200) {
        commit(VoicesMutationTypes.SET_PROJECT_DATA, projectRes.data.data)
      }
    } catch (error) {
      commit(VoicesMutationTypes.SET_API_ERROR_LOADER, true)
    }
    commit(VoicesMutationTypes.SET_LOADER, false)
  },
}

