import { getters, Getters } from './getters'
import { mutations, Mutations } from './mutations'
import { actions, Actions, VoicesActionTypes } from './actions'
import { Store as VuexStore, Module, CommitOptions, DispatchOptions } from 'vuex'
import type { State as RootState } from '@/store'
import { voicesLanguagesResponseType, vogUserData, projectData, selectedVoice, projectPagesUserData } from '@/types';


interface State {
  voicesAndLanguages: Array<voicesLanguagesResponseType>;
  userData: vogUserData;
  projectData: projectData;
  projectPagesUserData: projectPagesUserData;
  loader: boolean;
  apiErrorLoader: boolean;
  funcError: string;
  language: Array<string>;
  tempSelectedLanguage: string;
  selectedLanguage: string;
  selectedVoice: selectedVoice[];
  voiceSpeedValue: number;
  voicePitchValue: number;
  voiceSilenceValue: string;
  voiceEmphasisValue: string;
  setAudioURL: string;
  tempUserData: Array<vogUserData>;
  voiceType: string;
  // accessToken: string;
}

const state: State = {
  voicesAndLanguages: [],
  userData: {} as vogUserData,
  projectData: {} as projectData,
  projectPagesUserData: {} as projectPagesUserData,
  loader: false,
  apiErrorLoader: false,
  funcError: '',
  language: [],
  tempSelectedLanguage: 'en-GB',
  selectedLanguage: 'en-GB',
  selectedVoice: [],
  voiceSpeedValue: 1,
  voicePitchValue: 0,
  voiceSilenceValue: '',
  voiceEmphasisValue: '',
  setAudioURL: '',
  tempUserData: [],
  voiceType: 'Wavenet'
  // accessToken: ''
}

const voices: Module<State, RootState> = {
  state,
  mutations,
  actions,
  getters
}

export { State, VoicesActionTypes, Store }
export default voices

type Store<S = State> = Omit<VuexStore<S>, 'commit' | 'getters' | 'dispatch'> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
    key: K,
    payload: P,
    options?: CommitOptions
  ): ReturnType<Mutations[K]>;
} & {
  getters: {
    [K in keyof Getters]: ReturnType<Getters[K]>
  };
} & {
  dispatch<K extends keyof Actions>(
    key: K,
    payload: Parameters<Actions[K]>[1],
    options?: DispatchOptions
  ): ReturnType<Actions[K]>;
}
